<template>
  <div class="party">
    <OfficialHead ref="header" :homePageSetting="homePageSetting" />
    <!-- banner start -->
    <div class="banner">
      <el-image :src="require('@/assets/banner/policy-banner.png')" class="banner-img" fit="cover" />
    </div>
    <!-- banner end -->
    <div class="bg">
      <div class="container bg-white">
        <div class="row">
          <div class="col-12">
            <!-- swiper start -->
            <div class="swiper" v-if="swiperList.length">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div class="common-title">
                      <div class="title-progress"></div>
                      <div class="title-content">
                        <span class="title-text">说廉话策</span>
                        <a class="title-more" :href="`/bigPictureNews?id=${swiperQuery.articleListId}`" target="_blank">
                          <span class="more-text">更多</span>
                          <el-image :src="require('@/assets/dev/policy-more.png')" class="more-icon" fit="cover" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="swiper-content">
                      <swiper
                        ref="swiper"
                        :options="swiperOptions"
                      >
                        <swiper-slide v-for="item of swiperList" :key="item.id">
                          <a :href="getDetailUrl(item)" target="_blank">
                            <img :src="item.picture" class="slider-img" />
                            <div class="slider-content">
                              <span class="content-text">{{ item.title }}</span>
                            </div>
                          </a>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                        <div class="d-none d-lg-block swiper-button-prev" slot="button-prev"></div>
                        <div class="d-none d-lg-block swiper-button-next" slot="button-next"></div>
                      </swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- swiper end -->
            <!-- content start -->
            <div class="content">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6" v-if="partyList.length">
                    <div class="common-title">
                      <div class="title-progress"></div>
                      <div class="title-content">
                        <span class="title-text">党风廉政建设</span>
                        <a class="title-more" :href="`/bigPictureNews?id=${partyQuery.articleListId}`" target="_blank">
                          <span class="more-text">更多</span>
                          <el-image :src="require('@/assets/dev/policy-more.png')" class="more-icon" fit="cover" />
                        </a>
                      </div>
                    </div>
                    <div class="content-list">
                      <a class="list-item" v-for="(item, index) of partyList" :key="item.id" :href="getDetailUrl(item)" target="_blank">
                        <span class="item-icon" v-if="index !== 0"></span>
                        <el-image v-else :src="item.picture" class="item-img" fit="cover" />
                        <span class="item-title">{{ item.title }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6" v-if="disciplineList.length">
                    <div class="common-title">
                      <div class="title-progress"></div>
                      <div class="title-content">
                        <span class="title-text">纪法学习</span>
                        <a class="title-more" :href="`/bigPictureNews?id=${disciplineQuery.articleListId}`" target="_blank">
                          <span class="more-text">更多</span>
                          <el-image :src="require('@/assets/dev/policy-more.png')" class="more-icon" fit="cover" />
                        </a>
                      </div>
                    </div>
                    <div class="content-list">
                      <a class="list-item" v-for="(item, index) of disciplineList" :key="item.id" :href="getDetailUrl(item)" target="_blank">
                        <span class="item-icon" v-if="index !== 0"></span>
                        <el-image v-else :src="item.picture" class="item-img" fit="cover" />
                        <span class="item-title">{{ item.title }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- content end -->
          </div>
        </div>
      </div>
    </div>
    <!-- report start -->
    <div class="report">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="drawer-btns">
              <a class="drawer-btn" href="https://mp.weixin.qq.com/s?__biz=MzkwMjIzOTkwOA==&mid=100000239&idx=1&sn=cdf0ec6cf0ab511f7cd4b115f1ed114b&chksm=40a9c22477de4b3207fb65dd585f9d717d7e63800793b7558af52592f906f3da15c266035d29&mpshare=1&scene=1&srcid=0902dTRDNmTrlGsDvbTA9GFf&sharer_sharetime=1630571022378&sharer_shareid=744373745b56474adcc8bf6cf53f2be4#rd" target="_blank">
                <span>举报指南</span>
                <el-image :src="require('@/assets/images/report-guide.png')" class="btn-icon" fit="cover" />
              </a>
              <a class="drawer-btn" href="https://mp.weixin.qq.com/s?__biz=MzkwMjIzOTkwOA==&mid=100000242&idx=1&sn=f57190c9be9380d17325b0ccce3eb9de&chksm=40a9c23977de4b2fde7c86db996a90821ee2df9793b4dd281eb653635b367d9226ede231eec0&mpshare=1&scene=1&srcid=0902snjl0WaGyr41fYU0toDP&sharer_sharetime=1630571053833&sharer_shareid=744373745b56474adcc8bf6cf53f2be4#rd" target="_blank">
                <span>举报监督</span>
                <el-image :src="require('@/assets/images/report-supervision.png')" class="btn-icon" fit="cover" />
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="drawer-content">
              <div>来信举报</div>
              <div>来信方式：直接递送或邮寄至广路集团纪检监察室</div>
              <div>地址：广西南宁市兴宁区望州路298号万里商务大酒店七楼8716室</div>
              <div>邮编：530002</div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="drawer-content">
              <div>来访举报</div>
              <div>来访方式：可直接携带线索材料到广路集团纪检监察室，进行检举、控告</div>
              <div>地址：广西南宁市兴宁区望州路298号万里商务大酒店七楼8716室</div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="drawer-content">
              <div>来电举报</div>
              <div>联系电话：18076576681</div>
              <div>联系时间：非节假日上班时间</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- report end -->
    <OfficialFoot :homePageSetting="homePageSetting" />
  </div>
</template>

<script>
import { getHomePageSetting, setSeo } from '../api/defaultDataModel'
import OfficialHead from './OfficialHead'
import OfficialFoot from './OfficialFoot'

export default {
  name: 'PartyPolicy',
  components: {
    OfficialHead,
    OfficialFoot
  },
  data () {
    return {
      homePageSetting: getHomePageSetting(),
      swiperOptions: {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        loopedSlides: 5,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination'
        },
        on: {
          progress (progress) {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i)
              const slideProgress = this.slides[i].progress
              let modify = 1
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1
              }
              const translate = slideProgress * modify * 260 + 'px'
              const scale = 1 - Math.abs(slideProgress) / 5
              const zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
              slide.transform('translateX(' + translate + ') scale(' + scale + ')')
              slide.css('zIndex', zIndex)
              slide.css('opacity', 1)
              if (Math.abs(slideProgress) > 3) {
                slide.css('opacity', 0)
              }
            }
          },
          setTransition (transition) {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i)
              slide.transition(transition)
            }
          }
        }
      },
      swiperQuery: {
        page: 1,
        pageSize: 5,
        articleListId: null
      },
      partyQuery: {
        page: 1,
        pageSize: 6,
        articleListId: null
      },
      disciplineQuery: {
        page: 1,
        pageSize: 6,
        articleListId: null
      },
      swiperList: [],
      partyList: [],
      disciplineList: []
    }
  },
  created () {
    const { sid, pid, did } = this.$route.query
    const status = sid && pid && did && /^\d{15,}$/.test(sid) && /^\d{15,}$/.test(pid) && /^\d{15,}$/.test(did)
    if (status) {
      this.swiperQuery.articleListId = sid
      this.partyQuery.articleListId = pid
      this.disciplineQuery.articleListId = did
      this.getSetting()
      this.querySwiperList()
      this.queryPartyList()
      this.queryDisciplineList()
    } else {
      this.$router.push({ path: '/' })
    }
  },
  mounted() {
    window.removeEventListener('scroll', this.$refs.header.handleScroll)
    this.$refs.header.navigationHeaderClass = ['header', 'header-fixed']
  },
  methods: {
    async getSetting () {
      const res = await this.$http.get(this.TzConfig.BASE_URL + '/h5/homePageSetting/getSetting')
      const { data, code } = res.data
      if (code === 8000) {
        this.homePageSetting = data
        setSeo(
          this.homePageSetting.seoTitle,
          this.homePageSetting.seoDescription,
          this.homePageSetting.seoKeyword
        )
      }
    },
    async querySwiperList () {
      const res = await this.$http.get(this.TzConfig.BASE_URL + '/h5/article/list', { params: this.swiperQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list)) {
            this.swiperList = list
          }
        }
      }
    },
    async queryPartyList () {
      const res = await this.$http.get(this.TzConfig.BASE_URL + '/h5/article/list', { params: this.partyQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list)) {
            this.partyList = list
          }
        }
      }
    },
    async queryDisciplineList () {
      const res = await this.$http.get(this.TzConfig.BASE_URL + '/h5/article/list', { params: this.disciplineQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list)) {
            this.disciplineList = list
          }
        }
      }
    },
    getDetailUrl(news) {
      if (news) {
        if (1 === news.articleType) {
          return `/importantDetail?id=${news.id}`
        }
        return `/detail?id=${news.id}`
      }
      return '/'
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../assets/css/font.css';
  .party {
    width: 100%;
    min-height: 100vh;
    padding-top: 88px;
    font-family: pingFangSC-Medium !important;
    color: #222222;
    .common-title {
      .title-progress {
        height: 1px;
        background: #dedede;
      }
      .title-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        font-weight: 700;
        .title-text {
          position: relative;
          font-size: 36px;
          &:after {
            content: '';
            position: absolute;
            top: -20px;
            display: block;
            width: 100%;
            height: 3px;
            background: #c62b25;
          }
        }
        .title-more {
          display: flex;
          align-items: center;
          .more-text {
            font-size: 16px;
            color: #999999;
          }
          .more-icon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .banner {
      position: relative;
      width: 100%;
      height: 360px;
      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
    .bg {
      background: url('../assets/dev/party-bg.jpg');
      .bg-container {
        padding: 0;
      }
    }
    .swiper {
      .container {
        padding: 60px 15px 0;
        .swiper-content {
          width: 100%;
          .swiper-container {
            padding-bottom: 60px;
            .swiper-slide {
              position: relative;
              width: 520px;
              box-sizing: border-box;
              padding: 0 80px;
              .slider-img {
                width: 100%;
                height: 400px;
                box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
                object-fit: cover;
                background: rgba(255, 255, 255, 0.9);
              }
              .slider-content {
                position: absolute;
                bottom: 0;
                display: flex;
                align-items: center;
                width: calc(100% - 160px);
                height: 72px;
                box-sizing: border-box;
                padding: 0 20px;
                background: rgba(198, 43, 37, 0.902);
                .content-text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  font-size: 18px;
                  color: #ffffff;
                }
              }
            }
            .swiper-pagination {
              width: 100%;
              bottom: 10px;
              /deep/ .swiper-pagination-bullet {
                opacity: 1;
                width: 15px;
                height: 15px;
                border: 3px solid #fff;
                margin: 0 5px;
                background-color: #cccccc;
              }
              /deep/ .swiper-pagination-bullet-active {
                border: 3px solid #c62b25;
                background-color: #fff;
              }
            }
            .swiper-button-prev {
              top: 45%;
              left: 0;
              width: 45px;
              height: 45px;
              background: url('../assets/dev/policy-prev.png') no-repeat;
              background-position: 0 0;
              background-size: 100%;
            }
            .swiper-button-next {
              top: 45%;
              right: 0;
              width: 45px;
              height: 45px;
              background: url('../assets/dev/policy-next.png') no-repeat;
              background-size: 100%;
            }
          }
        }
      }
    }
    .content {
      .container {
        padding: 60px 15px 80px;
        .content-list {
          .list-item {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            font-size: 18px;
            font-weight: 700;
            &:nth-last-of-type(1) {
              margin-bottom: 0;
            }
            .item-img {
              width: 50%;
              height: 160px;
            }
            .item-icon {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 20px;
              background: #e9d2b7;
            }
            .item-title {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex: 1;
              font-size: 18px;
              &:nth-of-type(1) {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                white-space: initial;
                text-overflow: initial;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
    .report {
      background: #fef8e6;
      .container {
        padding-top: 40px;
        padding-bottom: 40px;
        .drawer-btns {
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          .drawer-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            border-radius: 50px;
            width: 250px;
            height: 66px;
            font-size: 24px;
            font-weight: 700;
            color: #ffffff;
            background: linear-gradient(to right, #c62b25, #d92a24);
            &:nth-last-of-type(1) {
              margin-left: 30px;
            }
            .btn-icon {
              width: 36px;
              height: 36px;
              margin-left: 10px;
            }
          }
        }
        .drawer-content {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          div {
            margin-bottom: 6px;
            &:nth-last-of-type(1) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .footer {
      margin: 0;
    }
  }
  @media (max-width: 992px) {
    .party {
      padding-top: 64px;
      .swiper {
        .container {
          padding: 40px 0 0 0;
        }
      }
      .content {
        .container {
          padding: 40px 0 60px 0;
          .col-12 {
            &:nth-of-type(2) {
              .common-title {
                margin-top: 40px;
              }
            }
          }
        }
      }
      .report {
        .container {
          padding-top: 30px;
          padding-bottom: 30px;
          .drawer-btns {
            margin-bottom: 20px;
          }
          .col-12 {
            &:nth-last-of-type(1) {
              .drawer-content {
                margin-bottom: 0;
              }
            }
          }
          .drawer-content {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    .party {
      .common-title {
        .title-content {
          .title-text {
            font-size: 24px;
          }
          .title-more {
            .more-text {
              font-size: 14px;
            }
            .more-icon {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
      .swiper {
        .container {
          .swiper-content {
            margin-top: 30px;
            .swiper-container {
              .swiper-slide {
                .slider-img {
                  height: 360px;
                }
                .slider-content {
                  .content-text {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
      .content {
        .container {
          .content-list {
            margin-top: 30px;
            .list-item {
              margin-bottom: 20px;
              .item-img {
                height: 140px;
              }
              .item-icon {
                margin-right: 15px;
              }
              .item-title {
                font-size: 16px;
                &:nth-of-type(1) {
                  margin-left: 15px;
                }
              }
            }
          }
        }
      }
      .report {
        .container {
          padding-top: 20px;
          padding-bottom: 20px;
          .drawer-btns {
            margin-bottom: 20px;
            .drawer-btn {
              width: 160px;
              height: 50px;
              font-size: 14px;
              &:nth-last-of-type(1) {
                margin-left: 10px;
              }
              .btn-guide {
                width: 24px;
                height: 24px;
              }
              .btn-icon {
                width: 22px;
                height: 24px;
              }
            }
          }
          .drawer-content {
            width: 100%;
            border: none;
            font-size: 14px;
            div {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
</style>
